import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SvgTestComponent } from './routes/svg-test/svg-test.component';
import { IndexComponent } from './routes/index/index.component';
import { CanvasComponent } from './routes/canvas/canvas.component';
import { SemTagsComponent } from './routes/sem-tags/sem-tags.component';
import { HtmlOpenwebComponent } from './routes/html-openweb/html-openweb.component';
import { MicrodataComponent } from './routes/microdata/microdata.component';
import { VideoComponent } from './routes/video/video.component';

const routes: Routes = [
  { path: '', component: IndexComponent },
  { path: 'svg-test', component: SvgTestComponent},
  { path: 'canvas', component: CanvasComponent},
  { path: 'sem-tags', component: SemTagsComponent},
  { path: 'html-openweb', component: HtmlOpenwebComponent},
  { path: 'microdata', component: MicrodataComponent},
  { path: 'video', component: VideoComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
