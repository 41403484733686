import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  menuShown = false;

  constructor() { }

  ngOnInit() {
  }

  burgerClick() {
    this.menuShown = !this.menuShown;
  }

  burgerOpenClick() {
    if (this.menuShown) {
      this.menuShown = false;
    }
  }
}
