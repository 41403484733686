import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
})
export class CanvasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    const canvas1 = document.querySelector('#canvas1') as HTMLCanvasElement;
    const ctx1: CanvasRenderingContext2D = canvas1.getContext('2d');


    ctx1.beginPath();
    ctx1.rect(10, 10, 50, 50);
    ctx1.fillStyle = '#FF0000';
    ctx1.fill();

    ctx1.beginPath();
    ctx1.rect(30, 30, 50, 50);
    ctx1.fillStyle = '#0000FF';
    ctx1.fill();
  }

}
