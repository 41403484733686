import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SvgTestComponent } from './routes/svg-test/svg-test.component';
import { IndexComponent } from './routes/index/index.component';
import { CanvasComponent } from './routes/canvas/canvas.component';
import { SemTagsComponent } from './routes/sem-tags/sem-tags.component';
import { HtmlOpenwebComponent } from './routes/html-openweb/html-openweb.component';
import { MicrodataComponent } from './routes/microdata/microdata.component';
import { VideoComponent } from './routes/video/video.component';
import { NavbarComponent } from './navbar/navbar.component';

@NgModule({
  declarations: [
    AppComponent,
    SvgTestComponent,
    IndexComponent,
    CanvasComponent,
    SemTagsComponent,
    HtmlOpenwebComponent,
    MicrodataComponent,
    VideoComponent,
    NavbarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
