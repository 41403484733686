import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-test',
  templateUrl: './svg-test.component.html',
  styleUrls: ['./svg-test.component.scss']
})
export class SvgTestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.getElementById('button').addEventListener('click', this.copyFunction);
  }

  // bruges ikke, er kun til inspiration
  copyText(val: string){
    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }

    // kan kopiere kode fra en bestemt Id, men har ikke kunne finde frem til at kunne kopiere fra forskellige Id's
    copyFunction(){
      let copyText = document.getElementById("Rectangle").textContent
      let textArea = document.createElement('textarea');
      textArea.textContent = copyText;
      document.body.append(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      }
}
